
export default {
  name: 'TopSeller',
  props: {
    sellers: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    seller() {
      return this.sellers[this.index];
    },
    isLast() {
      return this.index > 0 && this.index === this.sellers.length - 2;
    },
  },
};
